export type CheckoutStep =
  | "action"
  | "applet_features"
  | "applet_quota"
  | "convert_pro_plus_applet"
  | "default"
  | "default_pro"
  | "filter_code"
  | "holiday"
  | "multi_action"
  | "multi_service_account"
  | "plan_upgrade"
  | "pro_service_tqas"
  | "queries"
  | "trigger"
  | "ai_maker_rate_limit"

interface Copy {
  benefitsHeader: string
  checkoutModalTarget: string
  copy: string
  editAppletWarning: string
  eligibleForTrialCopy: string
  mainCTALabel: string
  targetPlan: string
  title: string
}

interface Props {
  appletFeatureFlags?: {
    intermediate_pro_features: boolean
    pro_features: boolean
  }
  currentTier?: string
  eligibleForTrial?: boolean
  eligibleTrialPeriod: number
  isTrialing?: boolean
  premiumService?: string
  step: CheckoutStep
  tierRequirement?: string
}

const modalCopy = ({
  appletFeatureFlags,
  currentTier,
  eligibleForTrial,
  eligibleTrialPeriod,
  isTrialing,
  premiumService,
  step,
  tierRequirement,
}: Props) => {
  const getTargetPlan = (tier: string) => (tier === "intermediate_pro" ? "Pro" : "Pro+")

  const result: Copy = {
    benefitsHeader: "",
    checkoutModalTarget: tierRequirement == "pro" ? "pro" : "intermediate_pro",
    copy: "",
    editAppletWarning: "",
    eligibleForTrialCopy: "",
    mainCTALabel: eligibleForTrial ? "Try it free" : "Upgrade",
    targetPlan: getTargetPlan(tierRequirement as string),
    title: "",
  }

  if (["queries", "filter_code", "multi_service_account", "multi_action", "convert_pro_plus_applet"].includes(step)) {
    if (appletFeatureFlags?.pro_features) {
      result.editAppletWarning = "This Applet uses Pro+ features. "
    } else if (appletFeatureFlags?.intermediate_pro_features) {
      result.editAppletWarning = "This Applet uses Pro features. "
    }
  }

  switch (step) {
    case "applet_quota":
      result.title = "You have reached your Applet limit"
      if (currentTier === "intermediate_pro" && isTrialing) {
        result.copy = ""
      } else if (currentTier === "intermediate_pro") {
        result.copy = "Upgrade now for unlimited Applets and get access to queries, filter code, and multiple accounts."
      } else if (currentTier === "free") {
        result.checkoutModalTarget = "intermediate_pro"
        result.targetPlan = getTargetPlan(result.checkoutModalTarget)
        result.copy = eligibleForTrial
          ? `Get up to 20 Applets, access to multiple actions, and customer support.`
          : "Upgrade to IFTTT Pro to get up to 20 Applets, access to multiple actions, and customer support."
      }
      break
    case "applet_features":
      result.title = `This Applet has ${result.targetPlan} features`
      if (!eligibleForTrial) {
        result.copy = `Upgrade to IFTTT ${result.targetPlan} to enable this Applet.`
      }
      break
    case "default":
      result.title = eligibleForTrial ? "Try Pro+ for 7 days" : "Upgrade to Pro+"
      result.copy = "Upgrade to get access to queries, filter code, and multiple accounts."
      result.checkoutModalTarget = "pro"
      result.targetPlan = getTargetPlan(result.checkoutModalTarget)
      break
    case "default_pro":
      result.title = eligibleForTrial ? "Try Pro for 7 days" : "Upgrade to Pro"
      result.copy = "Upgrade to get up to 20 Applets, access to multiple actions, and customer support."
      result.checkoutModalTarget = "intermediate_pro"
      result.targetPlan = getTargetPlan(result.checkoutModalTarget)
      break
    case "convert_pro_plus_applet":
      result.copy = "Upgrade to get access to queries, filter code, and multiple accounts."
      break
    case "plan_upgrade":
      if (currentTier === "legacy_pro") {
        result.title = "Add multiple accounts"
        result.copy = "Upgrade to IFTTT Pro+ to add and manage more accounts."
        result.checkoutModalTarget = "pro"
      } else {
        result.title = "Unlimited Applets"
        result.copy = "Upgrade to get access to queries, filter code, and multiple accounts."
      }
      break
    case "action":
    case "trigger":
      result.title = `Add a ${result.targetPlan} ${step}`
      result.copy = `Upgrade to IFTTT ${result.targetPlan} to add this ${step} to your Applets.`

      break
    case "queries":
      result.title = "Add a query"
      result.copy = "Upgrade to IFTTT Pro+ to add queries to your Applets."
      result.checkoutModalTarget = "pro"

      break
    case "pro_service_tqas":
      result.title = `Connect to ${premiumService}`
      result.copy = `Upgrade to IFTTT Pro to access ${premiumService} triggers and actions.`

      break
    case "filter_code":
      result.title = "Add filter code"
      result.copy = "Upgrade to IFTTT Pro+ to add filter code to your Applets."
      result.checkoutModalTarget = "pro"

      break
    case "multi_service_account":
      result.title = "Add multiple accounts"
      result.checkoutModalTarget = "pro"
      if (currentTier === "legacy_pro" || (currentTier === "intermediate_pro" && isTrialing)) {
        result.copy = "Upgrade to IFTTT Pro+ to add and manage more accounts."
      } else {
        result.copy =
          "Upgrade to IFTTT Pro+ to add and manage more accounts. You will also get access to unlimited Applets and filter code."
      }
      break
    case "multi_action":
      result.title = "Add more actions"
      result.copy = "Upgrade to IFTTT Pro to add multiple actions to your Applets."
      result.checkoutModalTarget = "intermediate_pro"

      // User could be editing an Applet with multi_action + Pro+ feature (filter code, query, etc)
      if (appletFeatureFlags?.pro_features) {
        result.copy = "Upgrade to IFTTT Pro+ to add multiple actions to your Applets."
        result.checkoutModalTarget = "pro"
      }

      result.targetPlan = getTargetPlan(result.checkoutModalTarget)

      break
    case "holiday":
      result.title = "Get 23% off Pro"
      result.copy = "Tech the halls with our best prices of the season."
      result.checkoutModalTarget = "intermediate_pro"
      result.targetPlan = getTargetPlan(result.checkoutModalTarget)

      break
    case "ai_maker_rate_limit":
      result.title = "More access to AI Applets"
      result.copy = "Upgrade to Pro to create more Applets with AI."
      result.checkoutModalTarget = "intermediate_pro"

      break
    default:
      throw new Error(`Unknown step: ${step}`)
  }

  result.eligibleForTrialCopy = eligibleForTrial && !["default_pro", "default"].includes(step)
    ? `You can trial ${getTargetPlan(result.checkoutModalTarget)} for ${eligibleTrialPeriod} days.`
    : ""

  result.benefitsHeader = result.checkoutModalTarget === "pro" ? "Limitless possibilities for small business owners." : "Tools for smart home and productivity."

  return result
}

export default modalCopy
